@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     20px;
$spacing-ratio:    0.5;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$text-color:       #221033;
$background-color: #FCFCFC;
$border-color:     $grey-color-light;
$border-color-secondary:     #FFF;
$brand-color:      #8849C6;
$brand-color-light:#A576D4;
$error-color:      #C64A49;


// Width of the content area
$content-wd-sm:    100%;
$on-sm:            800px;

$content-wd-md:    992px;
$on-md:            1200px;

$content-wd-lg:    1200px;
$on-lg:            1400px;

$content-wd-xl:    90%;


$border-radius: 5px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "base",
    "layout",
    "listings",
    "recipe"
;
