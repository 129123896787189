.recipe-javascript-error {
    background: $error-color;
    color: $background-color;
    text-align: center;
    padding: $spacing-unit * $spacing-ratio;
    border-radius: $border-radius;
}

.unit-usc, .unit-si {
    display: none;
}
.switch-units {
    display: none;
    color: $brand-color-light;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.display-unit-usc .unit-usc { display: inline; }
.display-unit-usc #switch-to-si { display: inline; }

.display-unit-si .unit-si { display: inline; }
.display-unit-si #switch-to-usc { display: inline; }

.sequential-recipe {
    padding-left: ($spacing-unit * $spacing-ratio);
    @include media-query($on-sm) { padding-left: 0; }

    h1, h2, h3 {
        margin-bottom: 0;
    }

    .recipe-source-link {
        color: #999;
    }

    h1 {
        margin-left: -1*($spacing-unit * $spacing-ratio);
        font-size: 32px;
        @include media-query($on-sm) {
            margin-left: 0;
            font-size: 28px;
        }
    }

    h2 {
        font-size: 26px;
        @include media-query($on-sm) { font-size: 22px; }
    }

    h3 {
        font-size: 20px;
        @include media-query($on-sm) { font-size: 18px; }
    }

    ol, ul {
        margin: 0;
        list-style: none;
    }

    .sequential-recipe-stage {

        border-bottom: thin solid #999;

        .sequential-recipe-section-name { font-weight: bold; }
        .sequential-recipe-section-desc { font-style: italic; float: right; }
        
        .sequential-recipe-stage-dep,
        .sequential-recipe-section-sequence {
            display: inline-block;
            font-weight: bold;
            border: thin solid #ccc;
            padding: 0 0.5em;
            margin-right: 0.5em;
            border-bottom-right-radius: 0.25em;
        }
        .sequential-recipe-section-sequence {
            border-color: #999;
            background: #eee;
            border-top: none;
        }

        .sequential-recipe-stage-body {
            width: 100%;
            display: flex;
        }
        .sequential-recipe-stage-ingredients {
            flex: 0.5 0 200px;
        }
        .sequential-recipe-stage-steps {
            flex: 1.0 0 400px;
        }

        .sequential-recipe-step-action-description {
            display: block;
            color: $grey-color;
        }
    }

    .sequential-recipe-stage:last-of-type {
        border-bottom: thin solid black;
    }
    .sequential-recipe-stage:first-of-type {
        border-top: thin solid black;
    }

    .recipe-tree {
        display: flex;

        @include media-query($on-sm) {
            display: none;
        }
        
        .ingredient {
            display: block;
            padding: 1px ($spacing-unit * $spacing-ratio * $spacing-ratio);
            margin-bottom: ($spacing-unit * $spacing-ratio * $spacing-ratio);
            margin-top: ($spacing-unit * $spacing-ratio * $spacing-ratio);
            background-color: $grey-color-light;
        }

        &>.lhs, &>.rhs {
            display: inline-block;
        }

        .lhs {
            flex-grow: 1;
        }

        .rhs {
            order: 1;
            cursor: default;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            border-left: thin solid black;
            padding: ($spacing-unit * $spacing-ratio * $spacing-ratio);
            
            span {
                display: block;
                transform: rotate(-90deg);
            }
        }

        .rhs:hover + .lhs, .rhs:hover {
            background: $grey-color-light;
        }
    }
}

.ingredient span:before, .ingredient:before {
    content: " ";
}