.post-list {
    margin: 0;
    list-style: none;

    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;

    @include media-query($on-sm) {
        margin: 0 -($spacing-unit*$spacing-ratio*$spacing-ratio);
        display: block;
    }
}

$listing-wd:    250px;

.listing-wrapper {
    display: inline-block;
    width: $listing-wd + 2px;
    margin: 0 5px 10px;

    
    @include media-query($on-sm) {
        display: block;
        width: 100%;
        margin: 0 0 $spacing-unit;
    }
}

.listing {
    display: block;

    background-color: #FFF;
    border: 1px solid $border-color;
    box-shadow: inset 0 0 0 2px #FFF;
    border-radius: 5px;

    h2 {
        text-align: center;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: -.5px;
        margin: 0 1px;
        padding: 2px 5px 7px;
    }

    p {
        margin: 0 5px;
    }

    @include media-query($on-sm) {
        background-color: $background-color;
        border-radius: 0;
        box-shadow: none;
        border: 0;
    }
}

.post-link {
    &,
    &:visited,
    &:hover {
        text-decoration: none;
        color: $text-color;
    }
}

.post-date {
    font-size: $small-font-size;
    text-align: right;
    padding: 0 1em;
    color: $grey-color;
}

.post-icon {
    width: $listing-wd;
    height: $listing-wd;

    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    background-attachment: center;
    background-size: cover;

    @include media-query($on-sm) {
        width: 100%;
        height: 150px;
        border-radius: 0;
    }
}

.category-tree {
    & ul {
        margin-left: 2 * $spacing-unit;
        @include media-query($on-sm) { margin-left: $spacing-unit*$spacing-ratio; }
        list-style: none;
    }

    & ul ul {
        // border-left: 1px solid $border-color;
    }

    &>ul {
        margin-left: 0;
    }

    li {
        padding-left: $spacing-unit*$spacing-ratio;
    }
    &>ul>li {
        padding-left: 0;
        display: inline-block;
        margin: 0 $spacing-unit $spacing-unit;
    }

    a:nth-last-child(2) {
        display: block;
        @extend %bottomline;
    }

    li {
        font-size: 32px;
        @include media-query($on-sm) { font-size: 28px; }
    }

    li li {
        font-size: 26px;
        @include media-query($on-sm) { font-size: 22px; }
    }

    li li li {
        font-size: 20px;
        @include media-query($on-sm) { font-size: 18px; }
    }
}