/**
 * Site header
 */
.site-header {
    border-top: 5px solid $text-color;
    min-height: 56px;
    @extend %bottomline;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.under-construction {
    margin-left: 10px;
    font-size: 36px;
    color: $grey-color;

    @include media-query($on-sm) {
        display: block;
        margin: 0;
        font-size: 14px;
        position: absolute;
        left: 0;
    }
}

.site-title {
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    font-size: 36px;
    @include media-query($on-sm) {
        font-size: 28px;
    }

    &:hover {
        text-decoration: none;
    }

    &,
    &:visited {
        color: $text-color;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    @include media-query($on-sm) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $border-color;
        box-shadow: inset 0 0 0 1px #FFF;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: (15px + 2*9px);
            line-height: 0;
            padding-top: 9px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $text-color;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    padding: $spacing-unit 0 0;
 
    color: $grey-color;

    a, a:visited {
        color: $brand-color-light;
    }
}

.footer-heading {
    font-size: 18px;
}

.footer-contact {
    margin: 0 0 10px 0;

    width: 350px;
    @include media-query($on-sm) {
        width: inherit;
    }

    img {
        width: 110px;
        height: 110px;
        float: left;
        margin-right: 10px;
        border-radius: 10px;
    }

    & ul {
        list-style: none;
        margin-left: 0;
    }

    @extend %clearfix;
}

.footer-col-wrapper {    
    @extend %clearfix;
}

.footer-col {}

.footer-col-contact {
    float: left;
    width: 45%;
}

.footer-col-description {
    float: right;
    max-width: 600px;
    padding-left: $spacing-unit;
}

@include media-query($on-sm) {
    .footer-col {
        float: none;
        width: 100%;
        padding: 0;
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
    @extend %bottomline;
}

.page-nav-crumb {
    margin-bottom: $spacing-unit;

    a {
        // background: $grey-color-light; // $brand-color-light;
        border: 1px solid $border-color;
        box-shadow: inset 0 0 1px #FFF;

        padding: 2px 5px;
        border-radius: 4px;

        &:hover {
            background: $brand-color;
            text-decoration: none;
            color: $background-color;
        }
    }
}

/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    color: $brand-color;

    @include media-query($on-sm) {
        font-size: 36px;
    }
}

.post-meta {
    padding-bottom: 3px;
    &>:last-child { float: right; }
    @extend %clearfix;
}

.post-list-meta {
    margin-bottom: $spacing-unit;
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-sm) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-sm) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-sm) {
            font-size: 18px;
        }
    }
}
